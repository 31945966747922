import React from 'react';
import styled from 'styled-components';

import UpcomingEvents from './compo/countdown';
import CTA from './compo/cta';
import HeroImageCarousel from './compo/hero_carousel';


import './home.css'

import localimage from './GBMPoster.jpeg'
import bookclub from './BookClub.jpeg'
import Birayni from './Biryani.jpeg'
import qawwaliNight from './QawwaliNight.jpeg'

import IftaarPoster from './IftaarPoster.jpeg'
import UrduWorkShop from "./UrduWorkshop.jpeg"
import SocialProfileV2 from './compo/social_stuff';
import AboutUs from './compo/aboutus';
import EventCountdown from './compo/nextevent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9f9f9;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #333;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 1.5rem;
  color: #555;
  text-align: center;
  max-width: 600px;
  margin-bottom: 30px;
`;

const Button = styled.a`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;
const mockEvents = [
    { id: 4, name: "Urdu Workshop", date: "Biweekly" , time:"18:00",image:UrduWorkShop,description:"The Urdu Workshop by the Pakistani Student Association (PSA) at UTD offers students a bi-weekly chance to delve into the intricacies of the Urdu language. Through engaging activities like vocabulary building and conversational practice, participants, whether beginners or advanced learners, immerse themselves in Pakistani culture. Led by knowledgeable facilitators, the workshop provides not just language skills but also a deeper understanding of Pakistan's rich heritage."},
    { id: 1, name: "GBM", date: "Sept. 11, 2024", time:"18:30",image:localimage,description:"The Pakistan Student Association's general body meeting provides as a valuable opportunity for students to connect with the Pakistani community and immerse themselves in the culture. During these meetings, held every semester,  members discuss upcoming events, opportunities to represent the Pakistani community, and key initiatives of the PSA, allowing students to actively engage with and explore the rich Pakistani heritage. It is a platform for like-minded individuals to come together, share experiences, and build lasting friendships while promoting cultural awareness and unity. These gatherings not only strengthen bonds within the organization, however also create a welcoming space for all students interested in learning more about Pakistan and its diverse traditions."},
    { id: 2, name: "Biryani Night", date: "Sept. 27, 2024", time:"19:30" ,image:Birayni,description:"Get ready for an electrifying evening at Biryani Night hosted by the Pakistani Student Association! Savor the mouthwatering flavors of authentic Biryani, enjoy dazzling cultural performances, and dance the night away on our open dance floor. It’s a night of fun, food, and unforgettable memories."},
    { id: 3, name: "Bookclub", date: "Oct. 09, 2024", time:"17:30" ,image:bookclub,description:"Join us for our Book Club event featuring “Salt and Saffron” by Kamila Shamsie! Dive into this captivating novel that weaves together themes of family, history, and identity. Engage in lively discussions, share your insights, and connect with fellow book lovers. Whether you’re a seasoned reader or new to the book club, this event promises enriching conversations and a deeper appreciation for Shamsie’s storytelling."},
    { id: 5, name: "Dholki", date: "Oct 27, 2024" , time:"19:00", description:"Join us for a vibrant Dholki Night hosted by the Pakistani Student Association! This girls-only event celebrates the rich tradition of Pakistani culture with lively dholki music, traditional songs, and dance. Immerse yourself in the joyous atmosphere, adorned with colorful decor and delicious snacks. It’s a perfect opportunity to connect, celebrate, and create unforgettable memories with friends. Don’t miss out on this cultural extravaganza!"},
    { id: 5, name: "Mock Mehndi", date: "Nov 09, 2024" , time:"19:00", description:"Experience the vibrant traditions of a Pakistani wedding at our Mock Mehndi event! Immerse yourself in the joyous atmosphere with colorful decorations, traditional music, and intricate henna designs. Enjoy lively dance performances, delicious food, and the chance to participate in fun wedding rituals. Whether you’re familiar with Mehndi ceremonies or new to the experience, this event promises a night of cultural celebration and unforgettable memories. Don’t miss out on this enchanting evening!"},
    // ... add more events
  ];
  const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const nextEventDate = mockEvents.reduce((nextDate, event) => {
  var eventDateTime = event.date + " " + event.time;
  const eventDate = new Date(eventDateTime);
  return eventDate > new Date() && (!nextDate || eventDate < new Date(nextDate)) ? eventDateTime : nextDate;
}, null);

const nextEventName = mockEvents.reduce((nextDate, event) => {
  const eventDate = new Date(event.date);
  return eventDate > new Date() && (!nextDate || eventDate < new Date(nextDate))
    ? event.name // Use event name here
    : nextDate;
}, null);


const LandingPage = () => {
    return (
        <div class="App" >
          <HeroImageCarousel/>
          {nextEventDate && <EventCountdown nextEventDate={nextEventDate}   eventName={nextEventName}/>}
          <AboutUs/>
          <CenteredContainer>
            <SocialProfileV2/>
            
          </CenteredContainer>
          <CTA />
          
          <UpcomingEvents events={mockEvents} />
          
        </div>
    );
}

export default LandingPage;

