import React, { useEffect, useState } from 'react';
import QrReader  from 'react-qr-scanner';
import FailedCheckinModal from './FailedCheckinModal';
import SuccessCheckinModal from './SuccessCheckinModal';
import { useNavigate } from 'react-router-dom';

const Checkin = () => {
  const navigate = useNavigate();

  useEffect(() => 
    {
      if(!("token" in sessionStorage) || sessionStorage.getItem('token') != process.env.REACT_APP_TOKEN) {
        console.log("Token not present");
        navigate("/boardlogin");
        console.log("Past the navigate");
      }
      else {
        console.log("Token is present");
      }
    }
  )

  const [isScanned, setIsScanned] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [fullName ,setFullName] = useState('');
  const [email,setEmail] = useState('');
  const [ticketCount,setTicketCount] = useState(0);
  const [paid, setPaid] = useState(0);
  const [id, setId] = useState('');
  const [response, setResponse] = useState('');

  const COMPLETED_STATUS = "COMPLETED";
  const REJECTED_STATUS = "REJECTED";

  async function handleScan(result) {
    if(result != null){
      setIsScanned(true);
      console.log(result.text);
      setId(result.text);
      console.log(id);
      const response = await fetch(`https://api.psautd.org/tickets/get_ticket?idString=${encodeURIComponent(result.text)}`, {
              method: "GET",
              headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "application/json",
              },
            })
      const responseJson = await response.json();
      setResponse(responseJson);
      console.log(responseJson);
      handleResponse(responseJson);
      setIsScanned(false);
    }
  }

  const handleResponse = response => {
    if(response.fullname != null) {
      setFullName(response.fullname);
      setEmail(response.email);
      setTicketCount(response.numoftickets);
      setPaid(response.paid);
    }
    if(response.checkInStatus == COMPLETED_STATUS) {
      setIsSuccess(true);
    }
    else if(response.checkInStatus == REJECTED_STATUS) {
      setIsSuccess(false);
    }
    setIsOpen(true);
  }

  function handleError(err) {
    console.log(err);
  }

  if(isScanned) {
    return (
      <h1>SCANNING</h1>
    )
  }
  else {
    return (
      <>
        {!isOpen &&
        <QrReader
          onScan={handleScan}
          onError={handleError}
          constraints={{
            audio: false,
            video: { facingMode: "environment" }
          }}
        />
        }
        {isOpen && isSuccess && <SuccessCheckinModal setIsOpen={setIsOpen} name={fullName} email={email} numoftickets={ticketCount} paid={paid} id={id}/> }
        {isOpen && !isSuccess && <FailedCheckinModal setIsOpen={setIsOpen} response={response} email={email} numoftickets={ticketCount} id={id}/>}
      </>
    );
  };
}

export default Checkin;