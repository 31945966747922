import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CountdownContainer = styled.div`
  font-size: 2rem;
  text-align: center;
  margin-left: 20px ;
  margin-top: 20px;
  margin-right: 20px;
  
  background-color: #d8e6dc;
  opacity: 0.8;


  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
const CrescentMoon = styled.span`
  display: inline-block;
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

const Star = styled.span`
  display: inline-block;
  clip-path: polygon(50% 0%, 61.8% 38.2%, 98.1% 38.2%, 68.4% 61.8%, 79.4% 95.1%, 50% 76.6%, 20.6% 95.1%, 31.6% 61.8%, 1.9% 38.2%, 38.2% 38.2%);
  background-color: white;
  width: 40px;
  height: 40px;
`;



const EventCountdown = ({ nextEventDate,eventName }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = new Date(nextEventDate) - now;

      if (difference > 0) {
        const timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };

        setTimeLeft(timeLeft);
      }
    };

    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    return () => clearInterval(timer);
  }, [nextEventDate]);

  return (
    <CountdownContainer>
  
      <Star />
      {`Our next event is `}
<strong>{eventName}</strong>
{` in ${timeLeft.days} days, ${timeLeft.hours} hours, ${timeLeft.minutes} minutes, and ${timeLeft.seconds} seconds.`}

      <Star />
      
    </CountdownContainer>
  );
};

export default EventCountdown;
