import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';

function ManualCheckinModal ({setIsOpen}) {

    const COMPLETED = "COMPLETED";
    const [ticketId, setTicketId] = useState(0);

    const handleTicketIdChange = e => {
        setTicketId(e.target.value);
    }

    function closeModal() {
        setIsOpen(false);
    }

    async function checkinTicket() {
        const response = await fetch("https://api.psautd.org/tickets/checkinTicket", {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                'id':ticketId
            }),
            })
        const responseJson = await response.json();
        console.log(responseJson);
        if(responseJson.checkInStatus == COMPLETED ){
            setIsOpen(false);
            window.location.reload(false);
        }
        else{
            alert("ERROR WITH CHECKING IN; TRY AGAIN");
        }
    }

    return (
        <div className='modal-checkin'>
          <div className='overlay'></div>
          <div className='modal-content-manual-checkin'>
            <div className='modal-header-manual-checkin'>
                <h2>Manual Checkin</h2>
            </div>
            <div className='modal-body-manual-checkin'>
                <label class="form-label" for="tickets">Ticket Id</label>
                <input value={ticketId} onChange={handleTicketIdChange} class="form-input" id="tickets" type="number" inputMode='numeric' step="1" min="1" required></input>
            </div>
            <div className='modal-content-buttons'>
                <button className='checkin-modal' onClick={checkinTicket}>Checkin</button>
                <button className="modal-exit" onClick={closeModal}>Exit</button>
            </div>
          </div>
        </div>
    );
}

export default ManualCheckinModal;