import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min';  // Add this line to include Bootstrap's JavaScript

// Dummy import statements for image (replace these with actual image imports)
import image1 from './images_hero/melagroup.jpeg'
import image2 from './images_hero/bettergbm.jpeg'
import image3 from './images_hero/image6.jpg'
import image4 from './images_hero/image8.jpg'
import image5 from './images_hero/MockMehndiBoard.jpeg'
import image6 from './images_hero/mockMehdni.jpeg'
import image7 from './images_hero/urduworkshop.jpeg'
import image8 from './images_hero/biryani.jpeg'
import image9 from './images_hero/Bonfire.jpeg'
import image10 from './images_hero/mominsart.jpeg'
import image11 from './images_hero/bettergbm.jpeg'


export default function HeroImageCarousel() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getCarouselItemHeight = () => {
        if (windowWidth >= 1200) return '800px';
        if (windowWidth >= 992) return '700px';
        if (windowWidth >= 768) return '600px';
        return '400px';
    };

    const getboxwidth = () => {
        if (windowWidth >= 1200) return '800px';
        if (windowWidth >= 992) return '300x';
        if (windowWidth >= 768) return '100px';
        return '400px';
    };

    return (
        <header>
            <div id="heroCarousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">

                    <div className="carousel-item active d-flex justify-content-center align-items-center" style={{ height: getCarouselItemHeight(), background: `url(${image11}) center/cover no-repeat` }}>

                        <div className="mask" style={{

                        }}>



                        </div>
                    </div>

                    <div className="carousel-item" style={{ height: getCarouselItemHeight(), background: `url(${image1}) center/cover no-repeat` }}>
                        <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                            {/* ... Your content here ... */}
                        </div>
                    </div>
                    <div className="carousel-item" style={{ height: getCarouselItemHeight(), background: `url(${image2}) center/cover no-repeat` }}>
                        <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                            {/* ... Your content here ... */}
                        </div>
                    </div>

                    <div className="carousel-item" style={{ height: getCarouselItemHeight(), background: `url(${image3}) center/cover no-repeat` }}>
                        <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                            {/* ... Your content here ... */}
                        </div>
                    </div>
                    <div className="carousel-item" style={{ height: getCarouselItemHeight(), background: `url(${image4}) center/cover no-repeat` }}>
                        <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                            {/* ... Your content here ... */}
                        </div>
                    </div>
                    <div className="carousel-item" style={{ height: getCarouselItemHeight(), background: `url(${image5}) center/cover no-repeat` }}>
                        <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                            {/* ... Your content here ... */}
                        </div>
                    </div>
                    <div className="carousel-item" style={{ height: getCarouselItemHeight(), background: `url(${image6}) center/cover no-repeat` }}>
                        <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                            {/* ... Your content here ... */}
                        </div>
                    </div>
                    <div className="carousel-item" style={{ height: getCarouselItemHeight(), background: `url(${image7}) center/cover no-repeat` }}>
                        <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                            {/* ... Your content here ... */}
                        </div>
                    </div>
                    <div className="carousel-item" style={{ height: getCarouselItemHeight(), background: `url(${image8}) center/cover no-repeat` }}>
                        <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                            {/* ... Your content here ... */}
                        </div>
                    </div>
                    <div className="carousel-item" style={{ height: getCarouselItemHeight(), background: `url(${image9}) center/cover no-repeat` }}>
                        <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                            {/* ... Your content here ... */}
                        </div>
                    </div>
                    <div className="carousel-item" style={{ height: getCarouselItemHeight(), background: `url(${image10}) center/cover no-repeat` }}>
                        <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                            {/* ... Your content here ... */}
                        </div>
                    </div>

                </div>

                <button className="carousel-control-prev" type="button" data-bs-target="#heroCarousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                </button>

                <button className="carousel-control-next" type="button" data-bs-target="#heroCarousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                </button>
            </div>
        </header>
    );
}
