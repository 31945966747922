import React from 'react';
import { useNavigate } from 'react-router-dom';

function CurrentEventTicket() {

  const navigate = useNavigate();

  const goToPayment = () => {
    navigate('/buy_tickets');
  }
  return (
    <div className='container containerGreen'>
      <h1>Upcoming Event</h1>
      <div style={{width:'100%', textAlign:'center'}}>
        <img src={process.env.PUBLIC_URL + '/EventPoster.png'}></img>
      </div>
      <button onClick={goToPayment}>Buy Tickets</button>
    </div>
  )
}
export default CurrentEventTicket;