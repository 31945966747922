import { useState } from 'react';
import './BoardLogin.css'
import { useNavigate } from 'react-router-dom';

function BoardLogin() {

    const navigate = useNavigate();

    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');

    async function verifyUser() {
        try{
            
            const response = await fetch("https://api.psautd.org/boardlogin/verify", {
                                    method: "POST",
                                    headers: {
                                    "Access-Control-Allow-Origin" : "*",
                                    "Content-type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        'username':username,
                                        'password':password
                                    }),
                                })

            const responseJson = await response.json();
            const responseCode = responseJson.response;
            setUsername('');
            setPassword('');
            if(responseCode == "REJECTED") {
                alert("UR MOM");
            }
            else {
                sessionStorage.setItem('token',responseCode);
            }
            if(sessionStorage.getItem('token') == process.env.REACT_APP_TOKEN) {
                navigate('/tickets/viewAll');
            }
        } catch(Error) {
            throw new Error();
        }
    }

    const handleUserNameChange = e => {
        setUsername(e.target.value);
    }

    const handlePasswordChange = e => {
       setPassword(e.target.value);
    }

    return(
        <div class="login-page">
            <div class="form">
                <form class="login-form">
                    <input type="text" value={username} onChange={handleUserNameChange} placeholder="username"/>
                    <input type="password" value={password} onChange={handlePasswordChange} placeholder="password"/>
                </form>
                <button onClick={verifyUser}>login</button>
            </div>
        </div>
    )
}

export default BoardLogin;