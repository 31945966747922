import './CheckinModals.css'
import {ReactComponent as Exit} from '../../images/exit.svg';

function SuccessCheckinModal({setIsOpen, name, email, numoftickets, paid, id}) {

    const COMPLETED = "COMPLETED";

    const closeModal = () => {
        setIsOpen(false);
    }

    async function checkInTicket() {
        const response = await fetch("https://api.psautd.org/tickets/checkinTicket", {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                'id':id
            }),
            })
        const responseJson = await response.json();
        console.log(responseJson);
        if(responseJson.checkInStatus == COMPLETED ){
            setIsOpen(false);
        }
        else{
            alert("ERROR WITH CHECKING IN; TRY AGAIN");
        }
    }

    return (
        <div className='modal-checkin'>
          <div className='overlay'></div>
          <div className='modal-content'>
            <div className='modal-header'>
                <h2>Successful Checkin</h2>
            </div>
            <div className='modal-body'>
                <h3>Name: {name}</h3>
                <h3>Email: {email}</h3>
                <h3>Ticket Count: {numoftickets}</h3>
                <h3>Paid: ${paid}</h3>
            </div>
            <div>
                <button className='checkin-modal' onClick={checkInTicket}>
                Checkin
                </button>
                <button className="close-modal" onClick={closeModal}>
                <Exit height={24} width={24}/>
                </button>
            </div>
          </div>
        </div>
    );
}

export default SuccessCheckinModal;