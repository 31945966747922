import './CheckinModals.css'

function FailedCheckinModal({setIsOpen, id, response, email, numoftickets}) {

    const rejectionStatus = response.rejectionStatus;
    const REJECTION_NOT_FOUND = "NOT_FOUND";
    const REJECTION_CHECKED_IN = "CHECKED_IN";

    const closeModal = () => {
        setIsOpen(false);
    }

    if(rejectionStatus == REJECTION_NOT_FOUND) {
        return (
            <div className='modal-checkin'>
                <div className='overlay'></div>
                <div className='modal-content-failed'>
                    <div className='modal-header-failed'>
                        <h2>Failed Checkin</h2>
                    </div>
                    <div className='modal-body-failed'>
                        <h3>Cannot find customer with id: {id}</h3>
                    </div>
                    <div>
                        <button className='close-modal-failed' onClick={closeModal}>
                        EXIT
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    else if(rejectionStatus == REJECTION_CHECKED_IN) {
        return (
            <div className='modal-checkin'>
                <div className='overlay'></div>
                <div className='modal-content-failed'>
                    <div className='modal-header-failed'>
                        <h2>Failed Checkin</h2>
                    </div>
                    <div className='modal-body-failed'>
                        <h3>{email} is already checked in</h3>
                        <h3>with {numoftickets} tickets</h3>
                    </div>
                    <div>
                        <button className='close-modal-failed' onClick={closeModal}>
                        EXIT
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default FailedCheckinModal;