import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTiktok,
    faFacebook,
    faWhatsapp,
    faInstagram
  } from "@fortawesome/free-brands-svg-icons";
  

export default function SocialProfile() {

    return (
        <div className='social-container'>
             <a href="https://www.tiktok.com/@utdpsa"
            className="tiktok social">
            <FontAwesomeIcon icon={faTiktok} size="2x" />
            </a>
            <a href="https://www.facebook.com/psa.utd/"
                className="facebook social">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
            <a href="https://chat.whatsapp.com/KQJSreEtv3kGXR8kJxAtoq" className="whatsapp social">
                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
            </a>
            <a href="https://www.instagram.com/psautd/?hl=en"
                className="instagram social">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
        </div>
    )

}