import './Square.css'
import React, { useEffect, useState } from 'react';
import validator from 'validator'

import { useNavigate } from 'react-router-dom';

import Event from "./IftaarPoster.jpeg"

function IftaarTicket() {
  
    const COMPLETED_STATUS = 'COMPLETED';
    const PAYMENT_REJECTED = 'PAYMENT_REJECTED';
    const EMAIL_FAILURE = 'EMAIL_FAILURE';
    const DATABASE_FAILURE = 'DATABASE_INSERT_FAILURE';
    const EMAIL_IN_USE = "EMAIL_USED";
    const UTD_EMAIL = "utdallas.edu";

    const [fullName, setFullName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');

    const navigate = useNavigate();
  
    function validateUtdEmail (email) {
        const emailArray = email.split("@");
        const emailDomain = emailArray[1].toLowerCase();
        if(emailDomain == UTD_EMAIL){
            return true;
        }
        return false;
    }
  
    async function verifyInputs () {
      if( !validator.isEmail(emailAddress)) {
        alert("Please enter a valid email address");
        return false;
      }
      /*if(!validateUtdEmail(emailAddress)) {
        alert("Not a valid UTD email");
        return false;
      }
      */

      try{
        const response = await fetch("https://api.psautd.org/tickets/iftaar", {
            method: "POST",
            headers: {
            "Access-Control-Allow-Origin" : "*",
            "Content-type": "application/json",
            },
            body: JSON.stringify({
            'fullName':fullName,
            'emailAddress':emailAddress,
            'numberOfTickets':1,
            'totalCost':0
            }),
        })
        const responseJson = await response.json();
        if(response.status < 200 || response.status > 299) {
            alert("SERVICE ERROR:/nBefore contacting PSA please make sure/n1. You have entered the right information/n2. HTTPS is shown on the URL/nIf so please contact UTD PSA");
        }
        handleResponse(responseJson);
        } catch (error) {
        alert("SERVICE ERROR:\n\nBefore contacting PSA please make sure\n1. You have entered the right information\n2. HTTPS is shown on the URL\n\nIf so please contact UTD PSA");
        throw new Error(error);
        }
      return true;
    }

    const handleResponse = paymentResponse => {
        if(paymentResponse.completionStatus == COMPLETED_STATUS) {
          navigate('./complete',{state:{
            'fullName':fullName,
            'emailAddress':emailAddress,
            'numberOfTickets':1,
          }});
          return;
        }
        
        const rejectionStatus = paymentResponse.errorStatus;
        if(rejectionStatus == EMAIL_IN_USE) {
          alert("You have already gotten a ticket using this UTD email");
        }
        else if(rejectionStatus == EMAIL_FAILURE) {
          alert("Unable to send your registration: Please take a screenshot and contact UTD PSA");
        }
        else if(rejectionStatus == DATABASE_FAILURE) {
          alert("Unable to confirm your registration: Please take a screenshot reach out to UTD PSA");
        }
        
    };
  
    const handleNameChange = e => {
      setFullName(e.target.value);
    }
  
    const handleEmailChange = e => {
      setEmailAddress(e.target.value);
    }
  

    return (
        <div class="container">
            <div class = "inner-container">
            <img class="event-image" src={Event} alt="Event Image"/>
            <div class="form-container">
            <h1>Total: $0</h1>
            <form>
                <label class = "form-label" for="fullName">Full Name</label>	
                <input class="form-input" value={fullName} onChange={handleNameChange} defaultValue={''} required id="fullName" type='text' maxlength="24"></input>

                <label class="form-label" for="emailAddress">Email Address</label>
                <input class="form-input" value={emailAddress} onChange={handleEmailChange} defaultValue={''} required id="emailAddress" type="email" maxlength="64"></input>

            </form>
            <button class="form-button" onClick={() => verifyInputs()}>Reserve Ticket</button>
            </div>
            </div>
         </div>

    );
  }
  
  export default IftaarTicket;