import React from 'react';
import './App.css';
import Navbar from './components';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Routes, Route, HashRouter } from 'react-router-dom';
// import LandingPage from '.home';
import Home from './pages/home';
import Square from './pages/Payments/Square';
import IftaarTicket from './pages/Payments/IftaarTicket';
import Checkin from './pages/Checkin/Checkin';
import SuccessPayment from './pages/Payments/SuccessPayment';
import CurrentEventTicket from './pages/CurrentEventTicket';
import TicketsView from './pages/Tickets/TicketsView';
import BoardLogin from './pages/Login/BoardLogin';
  
function App() {

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home/>}/>
        <Route path='/current_event' element={<CurrentEventTicket/>}></Route>
        <Route path='/buy_tickets' element={<Square/>}/>
        <Route path='/tickets/checkin' element={<Checkin/>}/>
        <Route path='/buy_tickets/complete' element={<SuccessPayment/>}/>
        <Route path='/tickets/viewAll' element={<TicketsView/>}/>
        <Route path='/boardlogin' element={<BoardLogin/>}/>
      </Routes>
    </Router>
  );
}
  
export default App;
