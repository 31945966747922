import React from 'react';
import styled from 'styled-components';

const EventsContainer = styled.div`
  padding: 20px;
  
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Event = styled.div`
  padding: 10px 0;

  margin-top :20px;

  border-bottom: 1px solid #e0e0e0;
  background-color: ${props => (props.isEven ? '#1a5019' : '#f9f9f9')};
  border-radius: 10px;
  
  &:last-child {
    border-bottom: none;
  }
`;

const EventFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const EventDetails = styled.div`
  @media (max-width: 576px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

const EventTitle = styled.h2`
  padding-left: 20px;
  font-size: 1.5rem;
  color: ${({ altColor }) => (altColor ? "#f9f9f9" : "#333")};

  @media (max-width: 576px) {
    padding-left: 20px;
    flex: 1;
  }
`;

const EventDate = styled.p`
  padding-left: 20px;
  color: ${({ altColor }) => (altColor ? "#f9f9f9" : "#888")};
  margin: 5px 0;

  @media (max-width: 576px) {
    padding-left: 0;
    flex: 1;
  }
`;

const EventDescription = styled.p`
  color: ${({ altColor }) => (altColor ? "#f9f9f9" : "#666")};
  margin: 10px 20px;
  flex-grow: 1;
  text-align: center;
  font-size: 1.2rem;  // default font-size
  
  @media (max-width: 1200px) {
    font-size: 1.2rem;
  }
  @media (max-width: 992px) {
    font-size: 1rem;
  }
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
  @media (max-width: 576px) {
    font-size: 0.8rem;
  }
`;

const EventImage = styled.img`
  max-width: 500px;
  height: auto;
  border-radius: 10px;
  margin-right: 20px;

  @media (max-width: 12000px) {
    width: 300px;
  }
  @media (max-width: 992px) {
    width: 200px;
  }
  @media (max-width: 768px) {
    width: 200px;
  }
  @media (max-width: 576px) {
    margin-right: 0px;
    width: 250px;
  }
`;

const UpcomingEvents = ({ events }) => {
  return (
    <EventsContainer>
      {events.map((event, index) => (
        <Event key={event.id} isEven={index % 2 === 0}>
          <EventFlexContainer>
            <EventDetails>
              <EventTitle altColor={index % 2 === 0}>{event.name}</EventTitle>
              <EventDate altColor={index % 2 === 0}>{event.date}</EventDate>
            </EventDetails>
            <EventDescription altColor={index % 2 === 0}>{event.description}</EventDescription>
            <EventImage src={event.image} alt={event.name} />
          </EventFlexContainer>
        </Event>
      ))}
    </EventsContainer>
  );
};

export default UpcomingEvents;
