import React, { useState } from 'react';
import './NavBar.css';
import SocialProfile from './SocialProfile';
import { useNavigate } from 'react-router-dom';
  
const Navbar = () => {

  const[ isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleNavigation = () => {
    document.getElementsByClassName("navigation")[0].classList.toggle("active");
  }

  const clickLogo = () => {
    navigate('./');
  }

  return (
    <div>
      <div className='logo'>
        <img src={process.env.PUBLIC_URL + '/psalogo.png'} alt='' onClick={clickLogo}></img>
      </div>
      <div className='navigation'>
        <div className='ham-btn' onClick={toggleNavigation}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className='links'>
          <div className='link'>
            <a href='/'>Home</a>
          </div>
          <div className='link'>
            <a href='/buy_tickets'>Buy Tickets</a>
          </div>
        </div>
        <SocialProfile/>
      </div>
    </div>
  );
};
  
export default Navbar;