import {ReactComponent as CheckMark} from '../../images/checkMark.svg';
import './SuccessPayment.css';
import {useLocation} from 'react-router-dom';

function SuccessPayment() {

    const location = useLocation();
    const state = location.state;

    return (
        <div className="parentDiv">
            <div style={{textAlign:'center', width:'100%', display:'inline-block'}}>
                <CheckMark fill='#f7f7f7' height={250} style={{width:"100%"}}/>     
            </div>
            <div style={{textAlign:'center',width:'100%', display:'inline-block'}}>
                <h2 style={{color:'#f7f7f7'}}>Order placed for {state.fullName}</h2>
                <h2 style={{color:'#f7f7f7'}}>Bought {state.numberOfTickets} tickets for ${state.cost}</h2>
                <h2 style={{color:'#f7f7f7'}}>Ticket registration sent to {state.emailAddress}</h2>
                <h1 style={{color:'#f7f7f7'}}>IF YOU HAVE NOT RECEIVED AN EMAIL PLEASE REACH OUT TO UTD PSA</h1>
            </div>
        </div>
    );
}

export default SuccessPayment;