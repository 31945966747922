import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTiktok,
    faFacebook,
    faWhatsapp,
    faInstagram
  } from "@fortawesome/free-brands-svg-icons";
  
  export default function SocialProfileV2() {
    return (
        <div className='social-container' style={{ textAlign: 'center',fontSize:50 }}>
          Our Upcoming Events
        </div>
    );
}


