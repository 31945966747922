import './PaymentModal.css'
import {
    ApplePay,
    GooglePay,
    CreditCard,
    PaymentForm,
  } from "react-square-web-payments-sdk";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as Exit} from '../../images/exit.svg';

function PaymentModal({setIsOpen, name, email, numoftickets, unitcost, totalcost}) {

    const fullName = name;
    const emailAddress = email;
    const numberOfTickets = numoftickets;
    const cost = totalcost;
    const unit = unitcost

    const COMPLETED_STATUS = 'COMPLETED';
    const PAYMENT_REJECTED = 'PAYMENT_REJECTED';
    const EMAIL_FAILURE = 'EMAIL_FAILURE';
    const DATABASE_FAILURE = 'DATABASE_INSERT_FAILURE';

    const navigate = useNavigate();

    const closeModal = () => {
        setIsOpen(false);
    }

    const handleResponse = paymentResponse => {
        if(paymentResponse.completionStatus == COMPLETED_STATUS) {
          navigate('./complete',{state:{
            'fullName':fullName,
            'emailAddress':emailAddress,
            'numberOfTickets':numberOfTickets,
            'cost':cost
          }});
          return;
        }
        
        const rejectionStatus = paymentResponse.errorStatus;
        if(rejectionStatus == PAYMENT_REJECTED) {
          alert("Your payment has been rejected: Please fix the information and try again.\nIf you think this is a mistake please contact UTD PSA");
        }
        else if(rejectionStatus == EMAIL_FAILURE) {
          alert("Unable to send your registration: Please take a screenshot and contact UTD PSA");
        }
        else if(rejectionStatus == DATABASE_FAILURE) {
          alert("Unable to confirm your registration: Please take a screenshot reach out to UTD PSA");
        }
        
      };

    return (
        <div className='modal-payment'>
            <div className='overlay'></div>
            <div className='modal-content-payment'>
                <div className='modal-header-payment'>
                    <h2>Make Payment</h2>
                </div>
                <div className='modal-body-payment'>
                    <div className='table'>
                        <table>
                            <tr>
                                <th>Name</th>
                                <th>Email Address</th>
                            </tr>
                            <tr>
                                <td>{fullName}</td>
                                <td>{emailAddress}</td>
                            </tr>
                        </table>
                    </div>
                    <div className='table'>
                        <table>
                            <tr>
                                <th>Description</th>
                                <th>Number of Tickets</th>
                                <th>Unit Cost</th>
                                <th>Total Cost</th>
                            </tr>
                            <tr>
                                <td>Event Tickets</td>
                                <td>{numberOfTickets}</td>
                                <td>${unit}</td>
                                <td>${cost}</td>
                            </tr>
                        </table>
                        <h6>*THIS AMOUNT IS NON-REFUNDABLE*</h6>
                    </div>
                    <div className='modal-container'>
                    <PaymentForm
                        applicationId = {process.env.REACT_APP_SQUARE_APPLICATION_ID}
                        locationId = {process.env.REACT_APP_SQUARE_LOCATION_ID}
                        createPaymentRequest={() => ({
                            countryCode: "US",
                            currencyCode: "USD",
                            total: {
                            amount: (cost.toString()),
                            label: "Total",
                            pending: true
                            },
                        })}
                        cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
                            try{
                            const response = await fetch("https://api.psautd.org/tickets/purchaseTicket", {
                                method: "POST",
                                headers: {
                                "Access-Control-Allow-Origin" : "*",
                                "Content-type": "application/json",
                                },
                                body: JSON.stringify({
                                'sourceId': token.token,
                                'fullName':fullName,
                                'emailAddress':emailAddress,
                                'numberOfTickets':numberOfTickets,
                                'totalCost':cost
                                }),
                            })
                            const responseJson = await response.json();
                            if(response.status < 200 || response.status > 299) {
                                alert("SERVICE ERROR:/nBefore contacting PSA please make sure/n1. You have entered the right information/n2. HTTPS is shown on the URL/nIf so please contact UTD PSA");
                            }
                            handleResponse(responseJson);
                            } catch (error) {
                            alert("SERVICE ERROR:\n\nBefore contacting PSA please make sure\n1. You have entered the right information\n2. HTTPS is shown on the URL\n\nIf so please contact UTD PSA");
                            throw new Error(error);
                            }
                        }
                        }
                    >
                        <CreditCard/>
                        <ApplePay />
                        <GooglePay />
                    </PaymentForm>
                    </div>
                </div>
                <div>
                    <button className="close-modal-payment" onClick={closeModal}>
                    <Exit height={32} width={32}/>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PaymentModal;