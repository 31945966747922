import './Square.css'
import PaymentModal from './PaymentModal';
import React, { useEffect, useState } from 'react';
import validator from 'validator'

import Event from "./Mehndi Ki Raat.jpeg"
import Colab from "./colab2.png"

function Square() {

    const GMAIL_EMAIL = 'gmail.com';
    const OUTLOOK_EMAIL = 'outlook.com';
  
    const [fullName, setFullName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [numberOfTickets, setNumberOfTickets] = useState('');
    const [cost, setCost] = useState(0);
    let TICKET_PRICE = 15;
    const [isOpen, setIsOpen] = useState(false);
  
  
    const verifyInputs = () => {
      if( !validator.isEmail(emailAddress)) {
        alert("Please enter a valid email address");
        return false;
      }
      if( isNaN(parseInt(numberOfTickets)) || numberOfTickets < 1) {
        alert("Please enter a number greater that 0 for ticket count");
        return false;
      }
      if( isNaN(parseInt(cost))) {
        alert("ERROR : Reach out to PSA Board with a screenshot");
        return false;
      }
      const emailArray = emailAddress.split("@");
      const emailDomain = emailArray[1].toLowerCase();
      if(emailDomain != GMAIL_EMAIL && emailDomain != OUTLOOK_EMAIL){
        alert("Please enter a valid gmail or outlook account");  
        return false;
      }

      setIsOpen(true);
      return true;
    }
  
    const handleNameChange = e => {
      setFullName(e.target.value);
    }
  
    const handleEmailChange = e => {
      setEmailAddress(e.target.value);
    }
  
    const handleTicketChange = e => {
      setNumberOfTickets(e.target.value);
    }
  
    useEffect(() => setCost(numberOfTickets * TICKET_PRICE), [numberOfTickets]);


    return (
      <div class="container">
        <div class = "inner-container">
          <img class="event-image" src={Event} alt="Event Image"/>
          <div class="form-container">
          <h1>Total: ${cost}</h1>
          <form>
            <label class = "form-label" for="fullName">Full Name</label>	
            <input class="form-input" value={fullName} onChange={handleNameChange} defaultValue={''} required id="fullName" type='text' maxlength="24"></input>

            <label class="form-label" for="emailAddress">Email Address</label>
            <input class="form-input" value={emailAddress} onChange={handleEmailChange} defaultValue={''} required id="emailAddress" type="email" maxlength="64"></input>

            <label class="form-label" for="tickets">Number of Tickets</label>
            <input class="form-input" value={numberOfTickets} onChange={handleTicketChange} id="tickets" type="number" inputMode='numeric' step="1" min="1" required></input>

          </form>
          <button class="form-button" onClick={() => verifyInputs()}>Make Payment</button>
          </div>
          </div>
      {isOpen && <PaymentModal setIsOpen={setIsOpen} name={fullName} email={emailAddress} numoftickets={numberOfTickets} unitcost={TICKET_PRICE} totalcost={cost}/> }
      </div>

    );
      
  }
  
  export default Square;