//Call to Action (Buy now tickets)
import React from 'react';
import styled from 'styled-components';

const CTABlock = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: #fff;
  border-radius: 10px;
`;

const CTAButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const CTA = () => {
  return (
    <CTABlock>
      <h2>Mehndi ki Raat tickets are live right now!</h2>
      <p>Hurry up limited number of tickets</p>
      <CTAButton href="/buy_tickets">Buy Tickets</CTAButton>
    </CTABlock>
  );
};

export default CTA;
