import React, { useState , useEffect } from 'react';
import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react";
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './TicketView.css'
import ManualCheckinModal from '../Checkin/ManualCheckinModal';

function TicketsView() {
    const navigate = useNavigate();

    if(!("token" in sessionStorage) || sessionStorage.getItem('token') != process.env.REACT_APP_TOKEN) {
        navigate("/boardlogin");
    }
    const [response,setResponse] = useState([]);
    const [ticketsList, setTicketsList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [nameSearch, setNameSearch] = useState('');

    function goToScanPage() {
        navigate('/tickets/checkin');
    }

    function openModal() {
        console.log("TEST");
        setIsOpen(true);
    }

    async function fetchData() {
        
        const fetchresponse = await fetch("https://api.psautd.org/tickets/get_all", {
                                    method: "GET",
                                    headers: {
                                    "Access-Control-Allow-Origin" : "*",
                                    "Content-type": "application/json",
                                    },
                                });
        /*
        const fetchresponse = await fetch("http://localhost:8080/tickets/get_all", {
            method: "GET",
            headers: {
            "Access-Control-Allow-Origin" : "*",
            "Content-type": "application/json",
            },
        });
        */
        const responseJson = await fetchresponse.json();
        console.log(responseJson);
        setResponse(responseJson);
        setTicketsList(responseJson);
    }

    const handleSearchChange = e => {
        setNameSearch(e.target.value);
        let searchedTicketList = [];
        for (var key in response) {
            let item = response[key];
            if(item.fullname.toLowerCase().includes(nameSearch.toLowerCase())) {
                searchedTicketList.push(item);
            }
        }
        setTicketsList(searchedTicketList);
    }

    useEffect(() => {
        fetchData();
      }, []);

    if(sessionStorage.getItem('token') == process.env.REACT_APP_TOKEN){
        return (
            <div class='tickets'>
                <button onClick={goToScanPage}>Scan Tickets</button>
                <button onClick={openModal}>Manual Checkin</button>
                <h1>Ticket Count: {ticketsList[0]==undefined ? 0 : ticketsList[0].totalcount}</h1>
                <form>
                    <input value={nameSearch} onChange={handleSearchChange} placeholder='Full Name'></input>
                </form>
                <Table hover style={{ width:'75vw', marginLeft:'auto', marginRight:'auto'}}>
                    <thead>
                        <tr>
                            <th class='text-center'>ID</th>
                            <th class='text-center'>Name</th>
                            <th class='text-center'>Email</th>
                            <th class='text-center'>Tickets</th>
                            <th class='text-center'>Paid</th>
                            <th class='text-center'>Is Checked In</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ticketsList.map((item, index) => (
                        <tr key={index}>
                            <td class='text-center'>{item.id}</td>
                            <td class='text-center'>{item.fullname}</td>
                            <td class='text-center'>{item.email}</td>
                            <td class='text-center'>{item.numoftickets}</td>
                            <td class='text-center'>{item.paid}</td>
                            <td class='text-center'>{item.checkedin ? "Yes" : "No"}</td>
                        </tr>
                        ))}
                    </tbody>
                </Table>
                {isOpen && <ManualCheckinModal setIsOpen={setIsOpen}/>}
            </div>
        );
    }
      
}
export default TicketsView;