import React from 'react';
import styled from 'styled-components';
import aboutuspic from './about_us_images/image5.jpeg'

const AboutUsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px;
  border-radius: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const AboutUsTitle = styled.h2`
  font-size: 2rem;
  color: #333;
`;

const AboutUsImage = styled.img`
  max-width: 300px;
  height: auto;
  border-radius: 10px;
`;

const AboutUsText = styled.p`
  font-size: 1.2rem;
  color: #555;
  text-align: center;
  margin-top: 20px;
  max-width: 800px;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
`;

const AboutUs = () => {
  return (
    <AboutUsContainer>
      <AboutUsTitle>Our Mission</AboutUsTitle>
      <AboutUsImage src= {aboutuspic} alt="About Us Image" />
      <AboutUsText>
      The Pakistan Student Association at the University of Texas at Dallas unites students to celebrate Pakistani culture through events and community engagement.
        
      </AboutUsText>
    </AboutUsContainer>
  );
};

export default AboutUs;
